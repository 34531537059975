import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_l = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - L'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>L</h2>
     <LatQuotesIntro />
    
     <p><b>Labor omnia vincit</b> - Work conquers all things. (Virgil)</p>
<p><b>Lacrima Christi</b> - Christ's tear</p>
<p><b>Lapsus calami</b> - A slip of the pen</p>
<p><b>Lapsus linguae</b> - A slip of the tongue</p>
<p><b>Lapsus memoriae</b> - A slip of the memory</p>
<p><b>Lares et penates</b> - Household gods</p>
<p><b>Latet anguis in herba</b> - A snake lies in the grass. (Vergil)</p>
<p><b>Latine dictum</b> - Spoken in Latin</p>
<p><b>Laudant illa, sed ista legunt</b> - Some (writing) is praised, but other is read. (Martialis)</p>
<p><b>Laudatores temporis acti</b> - Praisers of time past</p>
<p><b>Laudem virtutis necessitati damus</b> - We give to necessity the praise of virtue finding the benefit in what's needful</p>
<p><b>Laus Deo</b> - Praise be to God</p>
<p><b>Lectio brevior lectio potior</b> - The shortest reading is the more probable reading</p>
<p><b>Lector benevole</b> - Kind reader</p>
<p><b>Lectori Salutem (L.S.)</b> - Greetings to the reader</p>
<p><b>Legatus a latere</b> - Advisor from the side</p>
<p><b>Lege atque lacrima</b> - Read 'em and weep</p>
<p><b>Legum servi sumus ut liberi esse possimus</b> - We are slaves of the law so that we may be able to be free. (Cicero)</p>
<p><b>Leve fit, quod bene fertur, onus</b> - The burden is made light which is borne well. (Ovid)</p>
<p><b>Lex domicilii</b> - The law of a person's home country</p>
<p><b>Lex fori</b> - The law of the forum (country)</p>
<p><b>Lex loci</b> - The law of the place</p>
<p><b>Lex mala, lex nulla</b> - A bad law is no law. (St. Thomas Aquinas)</p>
<p><b>Lex non scripta</b> - The unwritten (common) law</p>
<p><b>Lex scripta</b> - The written law</p>
<p><b>Lex talionis</b> - The law of revenge</p>
<p><b>Libenter homines id quod volunt credunt</b> - Men gladly believe that which they wish for. (Caesar)</p>
<p><b>Liberae sunt nostrae cogitationes</b> - Our thoughts are free. (Cicero)</p>
<p><b>Libera te ex inferis</b> - Save yourself from hell</p>
<p><b>Libertas inaestimabilis res est</b> - Liberty is a thing beyond all price. (Corpus Iuris Civilis)</p>
<p><b>Liberum arbitrium</b> - Free will</p>
<p><b>Libra solidus denarius (L.S.D.)</b> - Pounds, shillings, pence</p>
<p><b>Licentia liquendi</b> - Liberty of speaking</p>
<p><b>Licentia poetica</b> - Poetic licence. (Seneca)</p>
<p><b>Licet</b> - It is allowed</p>
<p><b>Lingua franca</b> - Universal tongue</p>
<p><b>Literati</b> - Men of letters</p>
<p><b>Litterae humaniores</b> - The humanities</p>
<p><b>Loco citato (lc)</b> - In the passage just quoted</p>
<p><b>Locum tenens</b> - One occupying the place (used as an English noun meaning 'deputy')</p>
<p><b>Locus classicus</b> - The most authoritative source, Classical passage</p>
<p><b>Locus delicti</b> - The scene of the crime</p>
<p><b>Locus desperatus</b> - A hopeless passage</p>
<p><b>Locus enim est principum generationis rerum</b> - For place is the origin of things. (Roger Bacon)</p>
<p><b>Locus in quo</b> - The place in which something happens</p>
<p><b>Locus poenitentiae</b> - A place for repentance</p>
<p><b>Locus sigilli (l.s.)</b> - The place of the seal</p>
<p><b>Locus standi</b> - Place of standing</p>
<p><b>Longo intervallo</b> - After a long gap</p>
<p><b>Loquitur (loq.)</b> - He/she speaks</p>
<p><b>Luctor et emergo</b> - I struggle but I'll survive</p>
<p><b>Lumen naturale</b> - Natural light</p>
<p><b>Lupus est homo homini</b> - Man is wolf to man</p>
<p><b>Lupus in fabula</b> - The wolf in the tale (i.e. Speak of the wolf, and he will come) (Terence)</p>
<p><b>Lux et veritas</b> - Light and Truth</p>
<p><b>Lux mundi</b> - The light of the world</p>

   </Layout>
  )
}

export default LatQuotes_l
